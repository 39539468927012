import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { City, State } from "country-state-city";

const AllReferUser = () => {
  const userId = localStorage.getItem("user_id");
  const [userData, setUserData] = useState([]);
  const getUserProfile = async () => {
    const response = await fetch(
      "https://krushimitr.in/api/users/all-refer-users",
      {
        method: "post",
        body: JSON.stringify({ userId }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    console.log(data);
    if (data.user_data) {
      setUserData(data.user_data);
    } else {
      setUserData("");
    }
  };

  //   console.log(userData);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
            <h3>
                All Refer Users
            </h3>
        </div>
        <div className="col-lg-12">
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.mobile}</td>
                  <td>{item.address + " " + item.city}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllReferUser;
