import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import stateDistrictData from '../states-and-districts.json'


const SamanyaKamgarYojna = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const [selectedState, setSelectedState] = useState(userData.state);
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(userData.city);
    const [fullname, setFullname] = useState(userData.name);
    const [mobile, setMobile] = useState(userData.mobile);
    const [email, setEmail] = useState(userData.email);
    const [DOB, setDOB] = useState("");
    const [aadharFront, setAadharFront] = useState("");
    const [aadharBack, setAadharBack] = useState("");
    const [rashanFront, setRashanFront] = useState("");
    const [rashanBack, setRashanBack] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountNo, setAccountNo] = useState("");
    const [IFSCCode, setIFSCCode] = useState("");
    const [workerTrade, setWorkerTrade] = useState("");
    const [taluka, setTaluka] = useState(userData.taluka);
    const [village, setVillage] = useState("");
    const [post, setPost] = useState("");
    const [pin, setPin] = useState(userData.pincode);
    const [documents, setDocuments] = useState([]);
    const [profileImage, setProfileImage] = useState("");

    const getUserData = async () => {
        const data = await fetch("https://krushimitr.in/api/users/user-profile", {
            method: "post",
            body: JSON.stringify({ userId: userId }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        const getData = await data.json();
        if (getData.user_data !== "") {
            setUserData(getData.user_data);
            setSelectedState(getData.user_data.state);
            setDistrict(getData.user_data.city);
            setFullname(getData.user_data.name);
            setMobile(getData.user_data.mobile);
            setEmail(getData.user_data.email);
            setTaluka(getData.user_data.taluka);
            setPin(getData.user_data.pincode);
        }
    };
    const ApplyEKrushimitrForm = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("profileImage", profileImage);
        formData.append("selectedState", selectedState);
        formData.append("district", district);
        formData.append("fullname", fullname);
        formData.append("mobile", mobile);
        formData.append("email", email);
        formData.append("DOB", DOB);
        formData.append("aadharFront", aadharFront);
        formData.append("aadharBack", aadharBack);
        formData.append("rashanFront", rashanFront);
        formData.append("rashanBack", rashanBack);
        formData.append("bankName", bankName);
        formData.append("accountNo", accountNo);
        formData.append("IFSCCode", IFSCCode);
        formData.append("workerTrade", workerTrade);
        formData.append("taluka", taluka);
        formData.append("village", village);
        formData.append("post", post);
        formData.append("pin", pin);
        // formData.append("documents", documents);
        const result = await fetch("https://krushimitr.in/api/users/samanya-kamgar-yojna",
            {
                method: "post",
                body: formData,
            }
        ).then((result) => result.json());
        if (result.status === 201) {
            alert(result.result);
            navigate("/");
        } else {
            alert(result.result);
        }
    };




    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        setDistricts(stateDistrictData[state] || []);
    };


    useEffect(() => {
        getUserData();
    }, [])
    return (
        <>
            <div className="px-lg-5 px-2 py-5">
                <div className="px-lg-5 px-0">
                    <div
                        className="mx-auto text-center mb-5"
                        style={{ maxWidth: "80%" }}
                    >
                        <h1 className="display-5">सामान्य कामगार नोंदणी फॉर्म</h1>
                    </div>
                    <div className="row g-0">
                        <div className="col-lg-10 m-auto col-12">
                            <div className="card py-4 px-3">
                                <div className="row g-3">
                                    <div className="col-lg-12 col-12">
                                        <label>
                                            Full Name <span style={{ color: "red" }}>* <small>(Write First Name, Middle Name, Last Name)</small></span>
                                        </label>
                                        <input
                                            type="text"
                                            onChange={(e) => setFullname(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Your Name"
                                            defaultValue={userData.name}
                                        />
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-lg-4 col-12">
                                        <label>
                                            Mobile Number <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="number"
                                            onChange={(e) => setMobile(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Mobile"
                                            defaultValue={userData.mobile}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-12">
                                        <label>
                                            Email ID <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Your Email"
                                            defaultValue={userData.email}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-12">
                                        <label>
                                            Date Of Birth <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="date"
                                            onChange={(e) => setDOB(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Date Of Birth"
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="row g-3">
                                    <div className="col-lg-3 col-6">
                                        <label>
                                            Aadhar Front<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(e) => setAadharFront(e.target.files[0])}
                                            className="form-control px-2"
                                            placeholder="Aadhar Front"
                                        />
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <label>
                                            Aadhar Back<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(e) => setAadharBack(e.target.files[0])}
                                            className="form-control px-2"
                                            placeholder="Aadhar Back"
                                        />
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <label>
                                            Rashan Front<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(e) => setRashanFront(e.target.files[0])}
                                            className="form-control px-2"
                                            placeholder="Rashan Front"
                                        />
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <label>
                                            Rashan Back<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(e) => setRashanBack(e.target.files[0])}
                                            className="form-control px-2"
                                            placeholder="Rashan Back"
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="row g-3">
                                    <div className="col-lg-12 col-12">
                                        <h5>Bank Details</h5>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-lg-4 col-12">
                                        <label>Bank Name<span style={{ color: "red" }}>*</span></label>
                                        <select name="bank" className="form-select" onChange={(e) => setBankName(e.target.value)}>
                                            <option value="">Select One</option>
                                            <option value="Allahabad Bank">Allahabad Bank</option>
                                            <option value="Andhra Bank">Andhra Bank</option>
                                            <option value="Axis Bank">Axis Bank</option>
                                            <option value="Bandan Bank">Bandan Bank</option>
                                            <option value="Bank of Bahrain and Kuwait">Bank of Bahrain and Kuwait</option>
                                            <option value="Bank of Baroda">Bank of Baroda</option>
                                            <option value="Bank of India">Bank of India</option>
                                            <option value="Bank of Maharashtra">Bank of Maharashtra</option>
                                            <option value="Bassein Catholic Co-operative Bank">Bassein Catholic Co-operative Bank</option>
                                            <option value="Bhartiya Mahila Bank">Bhartiya Mahila Bank</option>
                                            <option value="BNP Paribas">BNP Paribas</option>
                                            <option value="Canara Bank">Canara Bank</option>
                                            <option value="Catholic Syrian Bank">Catholic Syrian Bank</option>
                                            <option value="Central Bank of India">Central Bank of India</option>
                                            <option value="City Union Bank">City Union Bank</option>
                                            <option value="Corporation Bank">Corporation Bank</option>
                                            <option value="Cosmos Bank">Cosmos Bank</option>
                                            <option value="DCB BANK Personal">DCB BANK Personal</option>
                                            <option value="Dena Bank">Dena Bank</option>
                                            <option value="Deustche Bank">Deustche Bank</option>
                                            <option value="Development Credit Bank">Development Credit Bank</option>
                                            <option value="Dhanlaxmi Bank">Dhanlaxmi Bank</option>
                                            <option value="Federal Bank">Federal Bank</option>
                                            <option value="HDFC Bank">HDFC Bank</option>
                                            <option value="ICICI Bank">ICICI Bank</option>
                                            <option value="IDBI Bank">IDBI Bank</option>
                                            <option value="Indian Bank">Indian Bank</option>
                                            <option value="Indian Overseas NetBanking">Indian Overseas NetBanking</option>
                                            <option value="Indusind Bank">Indusind Bank</option>
                                            <option value="ING Vysya Bank">ING Vysya Bank</option>
                                            <option value="J and K Bank">J and K Bank</option>
                                            <option value="Janta Sahakari Bank">Janta Sahakari Bank</option>
                                            <option value="Karnataka Bank">Karnataka Bank</option>
                                            <option value="Karur Vysya Bank">Karur Vysya Bank</option>
                                            <option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
                                            <option value="Lakshmi Vilas Bank">Lakshmi Vilas Bank</option>
                                            <option value="Mehsana Urban Co-op Bank">Mehsana Urban Co-op Bank</option>
                                            <option value="NKGSB Co-operative Bank">NKGSB Co-operative Bank</option>
                                            <option value="Oriental Bank Of Commerce">Oriental Bank Of Commerce</option>
                                            <option value="Punjab & Sind Bank">Punjab & Sind Bank</option>
                                            <option value="Punjab and Maharashtra Cooperative Bank">Punjab and Maharashtra Cooperative Bank</option>
                                            <option value="Punjab National Bank">Punjab National Bank</option>
                                            <option value="Ratnakar Bank Limited">Ratnakar Bank Limited</option>
                                            <option value="RBL Bank">RBL Bank</option>
                                            <option value="Saraswat Cooperative Bank">Saraswat Cooperative Bank</option>
                                            <option value="Shamrao Vithal Cooperative Bank">Shamrao Vithal Cooperative Bank</option>
                                            <option value="South Indian Bank">South Indian Bank</option>
                                            <option value="Standard Chartered Bank">Standard Chartered Bank</option>
                                            <option value="State Bank Of Bikaner and Jaipur">State Bank Of Bikaner and Jaipur</option>
                                            <option value="State Bank of Hyderabad">State Bank of Hyderabad</option>
                                            <option value="State Bank of India">State Bank of India</option>
                                            <option value="State Bank of Mysore">State Bank of Mysore</option>
                                            <option value="State Bank of Patiala">State Bank of Patiala</option>
                                            <option value="State Bank of Travancore">State Bank of Travancore</option>
                                            <option value="SVC Bank">SVC Bank</option>
                                            <option value="Syndicate Bank">Syndicate Bank</option>
                                            <option value="Tamilnad Mercantile Bank">Tamilnad Mercantile Bank</option>
                                            <option value="Tamilnadu Cooperative Bank">Tamilnadu Cooperative Bank</option>
                                            <option value="The Kalyan Janata Sahakari Bank">The Kalyan Janata Sahakari Bank</option>
                                            <option value="The Royal Bank of Scotland">The Royal Bank of Scotland</option>
                                            <option value="TJSB Bank (Erstwhile Thane Janata Sahakari Bank)">TJSB Bank (Erstwhile Thane Janata Sahakari Bank)</option>
                                            <option value="UCO Bank">UCO Bank</option>
                                            <option value="Union Bank of India">Union Bank of India</option>
                                            <option value="United Bank Of India">United Bank Of India</option>
                                            <option value="Vijaya Bank">Vijaya Bank</option>
                                            <option value="Yes Bank">Yes Bank</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <label>Account No.<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            type="number"
                                            onChange={(e) => setAccountNo(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Account No."
                                        />
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <label>IFSC Code<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            type="text"
                                            onChange={(e) => setIFSCCode(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="IFSC Code"
                                        />
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-lg-4 col-12">
                                        <label>Worker Trade<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            type="text"
                                            onChange={(e) => setWorkerTrade(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Worker Trade"
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className="row g-3">
                                    <div className="col-lg-12 col-12">
                                        <h5>Address Details</h5>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-lg-4 col-12">
                                        <label>State</label>
                                        <select id="state" value={selectedState} onChange={handleStateChange} className="form-select">
                                            <option value="">Select State</option>
                                            {Object.keys(stateDistrictData).map((state) => (
                                                <option key={state} value={state}>{state}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <label for="district">District</label>
                                        <select id="district" className="form-select" onChange={(e) => setDistrict(userData.city ? userData.city : e.target.value)}>
                                            <option value={userData.city}>{userData.city}</option>

                                            {districts.map((district) => (
                                                <option key={district} value={district}>{district}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <label>Taluka</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setTaluka(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Taluka"
                                            defaultValue={userData.taluka}
                                        />
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-lg-4 col-12">
                                        <label>Village</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setVillage(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Village"
                                        />
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <label>Post</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setPost(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Post"
                                        />
                                    </div>
                                    <div className="col-lg-4 col-6">
                                        <label>Pincode</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setPin(e.target.value)}
                                            className="form-control px-2"
                                            placeholder="Pincode"
                                            defaultValue={userData.pincode}
                                        />
                                    </div>
                                </div>
                                <div className="row g-3">
                                    {/* <div className="col-lg-6 col-12">
                                        <label>
                                            Documemt Upload <span style={{ color: "red" }}>* <small>(select multiple (TC,Marksheets,Caste etc.))</small></span>
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(e) => setDocuments(e.target.files)}
                                            className="form-control px-2"
                                            placeholder="Aadhar Front"
                                        />
                                    </div> */}
                                    <div className="col-lg-6 col-12">
                                        <label>
                                            Profile Image<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <input
                                            type="file"
                                            onChange={(e) => setProfileImage(e.target.files[0])}
                                            className="form-control px-2"

                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-4 col-lg-3 m-auto">
                                        <button
                                            className="btn btn-primary w-100"
                                            onClick={ApplyEKrushimitrForm}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Contact End --> */}
        </>
    );
}
export default SamanyaKamgarYojna