import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
const AppUpdateNotification = () => {
  const [addDialog, setAddDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const orderCmplt = useRef(null);
  const adminId = localStorage.getItem("admin_id");
  let success = [];
  const [filterData, setFilterData] = useState([]);

  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("Na");
  const [link, setLink] = useState("Na");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNotificationData = async () => {
    let all_notification = await fetch(
      "https://krushimitr.in/api/admin/all-app-update-notifications"
    );
    const allNotification = await all_notification.json();
    if (allNotification.status === 201) {
      allNotification.result.map((item) => {
        success.push(item);
      });
      setFilterData(allNotification.result);
    } else {
      alert(allNotification.result);
    }
  };

  useEffect(() => {
    getNotificationData();
  }, [getNotificationData]);

  const openNew = () => {
    setAddDialog(true);
  };

  const deleteNotification = async (id) => {
    const response = await fetch(
      "https://krushimitr.in/api/admin/delete-app-update-notifications",
      {
        method: "post",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    if (result.status === 201) {
      alert(result.result);
      window.location.reload();
    } else {
      alert(result.result);
    }
  };
  const filterApplyTemplate = (options) => {
    // console.log(options);
    return (
      <button
        type="button"
        className="btn btn-outline-danger btn-sm"
        onClick={() => deleteNotification(options._id)}
      >
        <i className="fa fa-trash"></i>
      </button>
    );
  };

  const headerComplete = (
    <div className="py-2">
      <div className="row">
        <div className="col-lg-6 d-flex">
          <h4 className="m-0">All App Update Notifications</h4>
        </div>
        <div className="col-lg-4">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="form-control ps-5"
            />
          </span>
        </div>
        <div className="col-lg-2">
          <button
            onClick={openNew}
            className="btn btn-outline-info btn-sm m-auto w-100"
          >
            <i className="pi pi-plus"></i> Add
          </button>
        </div>
      </div>
    </div>
  );
  const hideDialog = () => {
    setAddDialog(false);
  };
  const [image, setImage] = useState("");
  const StoreUpdateApp = async () => {
    if (!title || !message || !image || !link) {
      alert("Fill all mandatory fields");
    } else {
      const formData = new FormData();
      formData.append("date", date);
      formData.append("title", title);
      formData.append("message", message);
      formData.append("link", link);
      formData.append("image", image);
      const response = await fetch(
        "https://krushimitr.in/api/admin/add-app-update-notification",
        {
          method: "post",
          body: formData,
        }
      );
      const result = await response.json();
      if (result.status === 201) {
        alert(result.result);
        window.location.reload();
      } else {
        alert(result.result);
      }
    }
  };

  const showImage = (rowData) => (
    <img
      src={`https://krushimitr.in/upload/${rowData.noti_image}`}
      style={{ maxWidth: "100px", maxHeight: "100px" }}
      alt={rowData.category_image}
    />
  );
  const AddPriceDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="me-1"
        outlined
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="ms-1"
        onClick={StoreUpdateApp}
      />
    </React.Fragment>
  );

  return (
    <div>
      <Toast ref={toast} />

      <div className="card px-3 UserCardReports">
        <DataTable
          ref={orderCmplt}
          value={filterData}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
          globalFilter={globalFilter}
          header={headerComplete}
        >
          <Column
            field="#"
            header="Sr. No."
            bodyStyle={{
              fontSize: 15,
              fontWeight: "bold",
              textAlign: "center",
            }}
            body={(data, options) => options.rowIndex + 1}
          ></Column>
          <Column field="noti_date" header="Date" sortable></Column>
          <Column field="noti_title" header="Title" sortable></Column>
          <Column field="noti_message" header="Message" sortable></Column>
          <Column field="noti_link" header="Links" sortable></Column>
          <Column field={showImage} header="Image" body={showImage}></Column>
          <Column
            header="Action"
            body={filterApplyTemplate}
            severity="success"
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={addDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={"Add Notification"}
        modal
        className="p-fluid"
        footer={AddPriceDialogFooter}
        onHide={hideDialog}
      >
        <div className="row">
          <div className="col-lg-4">
            <label>Date</label>
            <Calendar
              value={date}
              onChange={(e) => setDate(e.value)}
              dateFormat="dd-mm-yy"
              placeholder="Date"
            />
          </div>

          <div className="col-lg-8">
            <label>
              Title <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-12">
            <label>
              Message <span className="text-danger">*</span>
            </label>
            <textarea
              rows="3"
              className="form-control"
              placeholder="Enter Subject"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-12">
            <label>
              Image <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              className="form-control"
              placeholder="Image"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-12">
            <label>
              Link <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Link"
              onChange={(e) => setLink(e.target.value)}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AppUpdateNotification;
