import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import loading from "../images/loading.gif";

const VDWithdrawRequest = () => {
  const [loadings, setLoadings] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [successData, setSuccessData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    requestDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    vendorName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    vendorMobile: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    upi_acc: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    ifsc: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const toast = useRef(null);
  const orderCmplt = useRef(null);

  const adminId = localStorage.getItem("admin_id");
  let success = [];
  const [filterData, setFilterData] = useState([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getRedeemRequestData = async () => {
    let all_transaction = await fetch(
      "https://krushimitr.in/api/distributor/get-all-vd-withdraw-request"
    );
    const allRequest = await all_transaction.json();
    if (allRequest.status === 201) {
      allRequest.result.map((item) => {
        success.push(item);
      });
      setFilterData(success);
      setSuccessData(success);
    } else {
      alert(allRequest.result);
    }
  };

  useEffect(() => {
    getRedeemRequestData();
  }, []);

  const [selectedRequest, setSelectedRequest] = useState("");
  const getRequestData = (id) => {
    successData.map((item) => {
      if (item._id === id) {
        setSelectedRequest(item);
      }
    });
  };

  const filterApplyTemplate = (options) => {
    if (options.status === "Accepted") {
      return <button className="btn btn-success btn-sm">Credited</button>;
    } else if (options.status === "Rejected") {
      return <button className="btn btn-danger btn-sm">Rejected</button>;
    } else {
      return (
        <>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm"
            onClick={() => rejectRequest(options._id)}
          >
            <i className="pi pi-ban"></i>
          </button>
          <button
            type="button"
            className="btn btn-outline-success btn-sm ms-1"
            onClick={() => {
              setAddDialog(true);
              getAllvndrdtbr(options.vendorId);
              getRequestData(options._id);
            }}
          >
            <i className="pi pi-eye"></i>
          </button>
        </>
      );
    }
  };

  const exportCSVS = () => {
    orderCmplt.current.exportCSV();
  };

  const rightToolbarTemplateCompleted = () => {
    return (
      <>
        <button
          className="ms-1 btn btn-outline-primary btn-sm"
          onClick={exportCSVS}
        >
          <i className="pi pi-file-excel"></i>{" "}
        </button>
        <button
          className="ms-1 btn btn-outline-danger btn-sm"
          onClick={exportPdf}
        >
          <i className="pi pi-file-pdf"></i>{" "}
        </button>
      </>
    );
  };

  const cols = [
    { field: "orderNumber", header: "Order No." },
    { field: "userName", header: "Name" },
    { field: "orderDate", header: "Date" },
    { field: "finalAmount", header: "Amount" },
    { field: "paymentStatus", header: "Pay Status" },
    { field: "paymentMethod", header: "Pay Method" },
    { field: "shippingAddress", header: "Shipping Address" },
    { field: "distName", header: "Distributor Name" },
    { field: "distAddress", header: "Distributor Address" },
    { field: "orderStatus", header: "Order Status" },
    { field: "deliveryStatus", header: "Delivery Status" },
    { field: "deliveryDate", header: "Delivery Date" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, successData);
        doc.save("users.pdf");
      });
    });
  };

  const [date1, setDate1] = useState(null);
  const headerComplete = (
    <div className="py-2">
      <div className="row">
        <div className="col-lg-4 d-flex">
          <p className="m-0">Vendor / Distributors Withdraw Requests</p>
        </div>
        <div className="col-lg-3">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="form-control ps-5"
            />
          </span>
        </div>
        <div className="col-lg-3 ">
          <div className="row">
            <div className="col-lg-6">
              <Calendar
                value={date1}
                onChange={(e) => setDate1(e.value)}
                dateFormat="dd-mm-yy"
                placeholder="From Date"
              />
            </div>
            <div className="col-lg-6">
              <Calendar
                onChange={(e) => {
                  showDateWiseData(e.value);
                }}
                dateFormat="dd-mm-yy"
                placeholder="To Date"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <Toolbar
            className="p-0 border-0"
            right={rightToolbarTemplateCompleted}
          ></Toolbar>
        </div>
      </div>
    </div>
  );
  const showDateWiseData = (date2) => {
    if (date2 !== "" && date1 !== "") {
      let newDate1 = new Date(date1).toISOString();
      let newDate2 = new Date(date2).toISOString();
      let Datas = [];
      successData.map((item) => {
        let newDate3 = moment(item.date, "DD-M-YYYY");
        let newDate4 = new Date(newDate3).toISOString();

        if (newDate4 >= newDate1 && newDate4 <= newDate2) {
          Datas.push(item);
        }
      });
      setFilterData(Datas);
    } else {
      setDate1("");
      return;
    }
  };

  const hideDialog = () => {
    setAddDialog(false);
  };

  const [UTRNoTrans, setUTRNoTrans] = useState("");
  const AcceptWithdrawRequest = async () => {
    setLoadings(true);
    let save_data = await fetch(
      "https://krushimitr.in/api/admin/accept_withdraw_request",
      {
        method: "post",
        body: JSON.stringify({
          id: selectedRequest._id,
          UTRNoTrans: UTRNoTrans,
          transactionId: selectedRequest.transactionId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const saveData = await save_data.json();
    if (saveData.status === 201) {
      setAddDialog(false);
      setLoadings(false);
      alert(saveData.result);
      window.location.reload();
    } else {
      setLoadings(false);
      alert(saveData.result);
    }
  };

  const rejectRequest = async (id) => {
    setLoadings(true);
    const response = await fetch(
      "https://krushimitr.in/api/admin/reject_withdraw_request",
      {
        method: "post",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    if (result.status === 201) {
      setLoadings(false);
      setAddDialog(false);
      alert(result.result);
      window.location.reload();
    } else {
      setLoadings(false);
      alert(result.result);
    }
  };
  const AddPriceDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="me-1"
        outlined
        onClick={hideDialog}
      />
      <Button
        label="Accept"
        icon="pi pi-check"
        className="ms-1"
        onClick={AcceptWithdrawRequest}
        disabled={loadings ? "disabled" : ""}
        aria-disabled={loadings ? "disabled" : ""}
      />
    </React.Fragment>
  );

  const [vndrdtbr, setvndrdtbr] = useState("");
  const getAllvndrdtbr = async (distributor_id) => {
    let result = await fetch(
      "https://krushimitr.in/api/distributor/distributor-profile",
      {
        method: "post",
        body: JSON.stringify({ distributor_id }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const uu = await result.json();
    setvndrdtbr(uu.distributor);
  };

  return (
    <div
      disabled={loadings ? "disabled" : ""}
      aria-disabled={loadings ? "disabled" : ""}
    >
      <Toast ref={toast} />
      <img
        src={loading}
        className={"loader " + (loadings ? "d-block" : "d-none")}
        alt=""
      />
      <div className="card px-3 UserCardReports">
        <DataTable
          ref={orderCmplt}
          value={filterData}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Vendor / Distributors"
          globalFilter={globalFilter}
          header={headerComplete}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={[
            "requestDate",
            "vendorName",
            "vendorMobile",
            "upi_acc",
            "ifsc",
            "status",
          ]}
        >
          <Column
            field="#"
            header="Sr. No."
            bodyStyle={{
              fontSize: 15,
              fontWeight: "bold",
              textAlign: "center",
            }}
            body={(data, options) => options.rowIndex + 1}
          ></Column>
          <Column field="requestDate" header="Date" sortable></Column>
          <Column field="transactionId" header="Txn Id" sortable></Column>
          <Column
            field="vendorName"
            header="Name"
            bodyStyle={{ fontWeight: "bold" }}
            sortable
          ></Column>
          <Column field="vendorMobile" header="Mobile" sortable></Column>
          <Column field="amount" header="Amont" sortable></Column>

          <Column
            field="upi_acc"
            header="UPI/Acc No"
            bodyStyle={{ fontWeight: "bold", paddingLeft: 10 }}
          ></Column>
          <Column
            field="ifsc"
            header="IFSC"
            bodyStyle={{ fontWeight: "bold", paddingLeft: 10 }}
          ></Column>
          <Column field="reason" header="Reason"></Column>
          <Column
            field="status"
            header="Status"
            sortable
            bodyStyle={{ fontWeight: "bold" }}
          ></Column>
          <Column
            header="Action"
            body={filterApplyTemplate}
            severity="success"
          ></Column>
        </DataTable>
      </div>
      <Dialog
        visible={addDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Accept Withdraw Request"
        modal
        className="p-fluid"
        footer={AddPriceDialogFooter}
        onHide={hideDialog}
      >
        <div
          className="row"
          disabled={loadings ? "disabled" : ""}
          aria-disabled={loadings ? "disabled" : ""}
        >
          <div className="col-lg-12 mt-2">
            <label>
              <b>Customer Name : </b> {selectedRequest.vendorName}
            </label>
          </div>
          <div className="col-lg-12 mt-2">
            <label>
              <b>Customer Mobile No. : </b>
              {selectedRequest.vendorMobile}
            </label>
          </div>
          <div className="col-lg-12 mt-2">
            <label>
              <b>UPI / Account No. : </b>
              {selectedRequest.upi_acc}
            </label>
          </div>
          <div className="col-lg-12 mt-2">
            <label>
              <b>IFCS : </b>
              {selectedRequest.ifsc}
            </label>
          </div>
          <div className="col-lg-12 mt-2">
            <label>
              <b>UTR No./Transaction No. : </b>
            </label>
            <input
              type="text"
              name=""
              onChange={(e) => {
                setUTRNoTrans(e.target.value);
              }}
              className="form-control"
              placeholder="Enter UTR No./Transaction No."
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default VDWithdrawRequest;
