import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import DownloadPDF from "./DownloadPDF";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const AllSamanyaKamgarForm = () => {
    const [time, setTime] = useState(false);
    const deleteApplication = async (id) => {
        let resultDel = await fetch(
            "https://krushimitr.in/api/admin/delete-application-form",
            {
                method: "post",
                body: JSON.stringify({ id }),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then((resultDel) => resultDel.json());
        if (resultDel.status === 201) {
            alert(resultDel.result);
            setTime(true);
        } else {
            alert(resultDel.result);
        }
    };
    const [addDialog, setAddDialog] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [allData, setAllData] = useState([]);
    useEffect(() => {
        const getAllApplication = async () => {
            const result = await fetch(
                "https://krushimitr.in/api/admin/all-kamgar-yojna-form"
            ).then((result) => result.json());
            setAllData(result.result);
        };
        getAllApplication();
    }, [time]);

    const reasonRef = useRef(null);
    const [rejectApplication, setRejectApplication] = useState(false);
    const [reason, setReason] = useState("");
    const getStatusChange = async (id, value) => {
        if (value === "Rejected" && reason === "") {
            return reasonRef.current.focus();
        } else {
            const result = await fetch(
                "https://krushimitr.in/api/admin/change_status_application-form",
                {
                    method: "post",
                    body: JSON.stringify({
                        id: id,
                        reason: reason ? reason : "",
                        type: value,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            const res = await result.json();
            if (res.status === 201) {
                alert(res.result);
                window.location.reload();
            } else {
                alert(res.result);
            }
        }
    };

    const filterApplyTemplate = (options) => {
        return (
            <>
                <button
                    type="button"
                    className="btn btn-primary btn-sm me-1"
                    onClick={() => { setAddDialog(true); setSelectedData(options) }}
                >
                    <i className="fas fa-eye"></i>
                </button>
            </>
        );
    };

    const [date1, setDate1] = useState(null);
    const headerComplete = (
        <div className="py-2">
            <div className="row">
                <div className="col-lg-8 d-flex">
                    <h4 className="m-0">All Samanya Kamgar Yojna Form</h4>
                </div>
                <div className="col-lg-4">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                            className="form-control ps-5"
                        />
                    </span>
                </div>
            </div>
        </div>
    );
    const images = (rowData) => {
        return (
            <img
                src={`https://krushimitr.in/upload/${rowData.profileImage}`}
                width={100}
                alt=""
            />
        );
    };

    const address = (rowData) => {
        return (
            <p>{`At. ${rowData.village}, Post ${rowData.post}, Tq. ${rowData.taluka}, Dist. ${rowData.city}, ${rowData.state} (${rowData.pin})`}</p>
        );
    };


    // const selectedData = {
    //         "_id": "66b9cc6c308fcd14b01aff69",
    //         "userId": "6556e8f5af8c2563c6c4160d",
    //         "profileImage": "profileImage-1723452524718.jpg",
    //         "selectedState": "Maharashtra",
    //         "district": "Aurangabad",
    //         "fullname": "Rajesh Ramji Banswal",
    //         "mobile": "undefined",
    //         "email": "undefined",
    //         "DOB": "1995-06-07",
    //         "aadharFront": "aadharFront-1723452524723.png",
    //         "aadharBack": "aadharBack-1723452524723.png",
    //         "rashanFront": "rashanFront-1723452524723.png",
    //         "rashanBack": "rashanBack-1723452524724.png",
    //         "bankName": "State Bank of India",
    //         "accountNo": "123654987012",
    //         "IFSCCode": "123456789",
    //         "workerTrade": "SE",
    //         "taluka": "undefined",
    //         "village": "Dongargaon",
    //         "post": "Anwi",
    //         "pin": "undefined",
    //         "status": "Pending",
    //         "createdAt": "2024-08-12T08:48:44.750Z",
    //         "updatedAt": "2024-08-12T08:48:44.750Z",
    //         "__v": 0
    //     }


    const hideDialog = () => {
        setAddDialog(false);
    };


    // const selectedRashanData = {
    //     image1: selectedData.rashanFront,
    //     image2: selectedData.rashanBack,
    // };


    const pdfContentRef = useRef();

    const downloadPDF = () => {
        let userName = selectedData.fullname;
        const content = pdfContentRef.current;


        html2canvas(content).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg');
            const pdf = new jsPDF();

            pdf.addImage(imgData, 'JPEG', 10, 10, 180, 160);
            pdf.save(`${userName}-images.pdf`);
        });
    };
    const AddDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="me-1"
                outlined
                onClick={hideDialog}
            />
            <button onClick={downloadPDF} className='btn btn-outline-info m-auto'><i className="fa fa-solid fa-file-pdf"></i> Download PDF</button>
        </React.Fragment>
    );



    return (
        <>
            <div className="card px-3">
                <DataTable
                    value={allData}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Forms"
                    globalFilter={globalFilter}
                    header={headerComplete}
                >
                    <Column
                        field="#"
                        header="Sr. No."
                        bodyStyle={{
                            fontSize: 15,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                        body={(data, options) => options.rowIndex + 1}
                    ></Column>
                    <Column field="fullname" header="Full Name" sortable></Column>
                    <Column field="mobile" header="Mobile" sortable></Column>
                    <Column
                        field="email"
                        header="Email"
                        sortable
                    ></Column>
                    <Column
                        field="address"
                        header="Address"
                        body={address}
                        sortable
                    ></Column>

                    <Column field="status" header="Status" sortable></Column>
                    <Column
                        field={images}
                        header="License Image"
                        body={images}
                        sortable
                    ></Column>
                    <Column
                        header="Action"
                        body={filterApplyTemplate}
                        severity="success"
                    ></Column>
                </DataTable>
                <Dialog
                    visible={addDialog}
                    style={{ width: "60rem" }}
                    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                    header={"Form Details"}
                    modal
                    className="p-fluid"
                    footer={AddDialogFooter}
                    onHide={hideDialog}
                >

                    <table className="table w-100 table-bordered formDetails" id="pdfContent" ref={pdfContentRef}>
                        <thead><tr>
                            <th colSpan={4}><h5 className="text-center">Samanya Kamgar Yojna Form Details</h5></th></tr></thead>
                        <tbody>
                            <tr>
                                <td>User Id :</td>
                                <td colSpan={2}>{selectedData.userId}</td>
                                <td rowSpan={4} className="text-center"> <img
                                    src={`https://krushimitr.in/upload/${selectedData.profileImage}`}
                                    width={'50%'}
                                    alt=""
                                /></td>
                            </tr>
                            <tr>
                                <td>Full name : </td>
                                <td colSpan={2}>{selectedData.fullname}</td>
                            </tr>
                            <tr>
                                <td>Mobile No. :</td>
                                <td colSpan={2}>{selectedData.mobile}</td>
                            </tr>
                            <tr>
                                <td>Email :</td>
                                <td colSpan={2}>{selectedData.email}</td>
                            </tr>
                            <tr>
                                <td>Date Of Birth : </td>
                                <td>{selectedData.DOB}</td>
                                <td>Worker Trade : </td>
                                <td>{selectedData.workerTrade}</td>
                            </tr>
                            <tr>
                                <td>Bank Name : </td>
                                <td>{selectedData.bankName}</td>
                                <td>Account No. :</td>
                                <td>{selectedData.accountNo}</td>
                            </tr>
                            <tr>
                                <td>IFSCCode</td>
                                <td>{selectedData.IFSCCode}</td>
                            </tr>
                            <tr>
                                <td>Village : </td>
                                <td>{selectedData.village}</td>
                                <td>Post : </td>
                                <td>{selectedData.post}</td>
                            </tr>
                            <tr>
                                <td>Taluka : </td>
                                <td>{selectedData.taluka}</td>
                                <td>District : </td>
                                <td>{selectedData.district}</td>
                            </tr>
                            <tr>
                                <td>State : </td>
                                <td>{selectedData.selectedState}</td>
                                <td>Pincode : </td>
                                <td>{selectedData.pin}</td>
                            </tr>
                            <tr>
                                <td>status</td>
                                <td>{selectedData.status}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="text-center">
                                    <p>Aadhar Front / Back</p>
                                    <DownloadPDF image1={selectedData.aadharFront} image2={selectedData.aadharBack} other={"aadhar"} />
                                </td>

                                <td colSpan={2} className="text-center">
                                    <p>Rashan Front / Back</p>
                                    <DownloadPDF image1={selectedData.rashanFront} image2={selectedData.rashanBack} other={"rashan"} />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </Dialog>
            </div>
        </>
    );
}

export default AllSamanyaKamgarForm