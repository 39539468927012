import React, { useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const DownloadPDF = ({ image1, image2, other }) => {
    const pdfContentRef = useRef();

    const downloadPDF = () => {
        const content = pdfContentRef.current;

        // Wait for all images to load
        const images = content.getElementsByTagName('img');
        const imagePromises = Array.from(images).map(img => {
            return new Promise((resolve) => {
                if (img.complete) {
                    resolve();
                } else {
                    img.onload = resolve;
                }
            });
        });

        Promise.all(imagePromises).then(() => {
            // Now, all images are loaded, we can capture the canvas
            html2canvas(content).then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg');
                const pdf = new jsPDF();

                pdf.addImage(imgData, 'JPEG', 10, 10, 180, 160);
                pdf.save(`${other}-images.pdf`);
            });
        });
    };

    return (
        <div>
            <div id="pdfContent" ref={pdfContentRef} style={{ textAlign: 'center' }}>
                <div>
                    <img
                        src={`https://krushimitr.in/upload/${image1}`}
                        width={'50%'}
                        alt={`${other} Front`}
                    />
                </div>
                <br />
                <br />
                <div>
                    <img
                        src={`https://krushimitr.in/upload/${image2}`}
                        width={'50%'}
                        alt={`${other} Back`}
                    />
                </div>
            </div>
            <div className='text-center mt-3' >
                <button onClick={downloadPDF} className='btn btn-outline-info m-auto'><i className="fa fa-solid fa-file-pdf"></i> Download PDF</button>
            </div>
        </div>
    );
};

export default DownloadPDF;
